import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { WorkflowRunContext } from 'contexts';
import React, { useContext, useEffect, useState } from 'react'

export default function CollabsableSection({ stepLog, sectionTitle, children, sectionIdx, activeSection, setActiveSection }) {
    const { activeStepLog } = useContext(WorkflowRunContext);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (stepLog?.id !== activeStepLog?.id) {
            setExpanded(false);
            setActiveSection(null);
        } else {
            if (activeSection !== sectionIdx) {
                setExpanded(false);
            } else {
                setExpanded(true);
            }
        }
    }, [activeStepLog, activeSection])

    const handleSectionTitleClick = () => {
        if (expanded) {
            setActiveSection(null);
        } else {
            setActiveSection(sectionIdx);
        }
    }

    return (
        <div className="px-6 py-4">
            <button className="flex justify-between w-full" onClick={handleSectionTitleClick}>
                <span className="font-semibold" >{sectionTitle}</span>
                {expanded ?
                    <ChevronUpIcon className="text-gray-600 h-5 w-5" />
                    :
                    <ChevronDownIcon className="text-gray-600 h-5 w-5" />
                }
            </button>
            {expanded && <>{children}</>}
        </div>
    );
}
