export default function TextItem({ runData }) {
    const value = !runData.value || runData.value.trim() === ' ' ? '\ufeff' : runData.value;

    return (
        <div>
            <label className="block text-base text-gray-700 font-semibold">{runData.valueId}</label>
            <div className="w-full border-transparent rounded-lg bg-gray-100 px-4 py-2 text-base break-all" >
                {value}
            </div>
        </div>
    )
}