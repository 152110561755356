import { useEffect, useState } from "react";
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { getOAuthAppConnection } from "../../../../api/workflowOAuthAppConnection";
import toast from "react-hot-toast";
import TextItem from "./Components/textItem";
import { workflowAppType } from "../../../../utils/constants";
import { getViewpointSettings } from "../../../../api/viewpoint";

export default function StepLogSetup({ stepLog }) {
    const [account, setAccount] = useState();

    const loadAction = useToastAction();

    useEffect(() => {
        loadData();
    }, [])

    const notFoundHandler = () => {
        toast.error(`Account (id=${stepLog.oAuthAppConnectionId}) is not found.`);
    }

    const getViewpointConnection = async () => {
        const viewpointSettings = await getViewpointSettings();
        const foundConnection = viewpointSettings.connections.find(x => x.id === stepLog.oAuthAppConnectionId);
        if (!foundConnection) {
            toast.error(`Viewpoint connection is not found.`);
        }
        return foundConnection;
    }

    const loadData = async () => {
        loadAction.execute(async () => {
            if (stepLog.appEvent?.type === workflowAppType.viewpoint) {
                const result = await getViewpointConnection();
                setAccount(result);
                return;
            }

            if (stepLog.oAuthAppConnectionId) {
                const result = await getOAuthAppConnection(stepLog.oAuthAppConnectionId, notFoundHandler);
                setAccount(result);
            }
        }, "Failed to load")
    }

    return (
        <div className="py-5 flex flex-col gap-y-3 w-full">
            <TextItem runData={{ valueId: "App", value: stepLog.appEvent?.name }} />
            <TextItem runData={{ valueId: "Event", value: stepLog.selectedEvent?.name }} />
            {
                stepLog.oAuthAppConnectionId && (
                    <TextItem runData={{ valueId: "Account", value: account?.name }} />
                )
            }
        </div>
    )
}