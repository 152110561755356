import React from 'react';

export const OidcRoutesContext = React.createContext();
export const WorkflowContext = React.createContext();
export const WorkflowValuesContext = React.createContext();
export const NodeItemContext = React.createContext();
export const WorkflowStepContext = React.createContext();
export const BranchContext = React.createContext();
export const OAuthAppConnectionContext = React.createContext();
export const SidebarContext = React.createContext();
export const AdminLayoutContext = React.createContext();
export const FolderContext = React.createContext();
export const FolderSidebarContext = React.createContext();
export const WorkflowRunContext = React.createContext();