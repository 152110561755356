import { XIcon } from "@heroicons/react/outline";
import { WorkflowRunContext } from "../../../../contexts";
import { useContext, useEffect, useState } from "react";
import StepLogView from "./stepLogView";
import LoopIterationLog from "./loopIterationLog";
import { workflowAppType } from "../../../../utils/constants";

export default function BranchStepLogModal({ branchLog, onCloseModal }) {
    const { runLog } = useContext(WorkflowRunContext);
    const [stepLogs, setStepLogs] = useState([]);

    useEffect(() => {
        if (branchLog?.workflowBranch?.id) {
            let foundStepLogs = runLog.stepLogs.filter(x => x.branchId === branchLog.workflowBranch.id);
            setStepLogs(foundStepLogs);
        }
    }, [branchLog?.workflowBranch.id])

    return (
        <div className="bg-gray-100 rounded-lg shadow-lg h-full w-full overflow-y-hidden flex flex-col items-center">
            <div className="bg-white rounded-t-lg border-b border-solid p-4 flex justify-between shadow-md w-full">
                <div className="font-semibold text-lg">{branchLog?.workflowBranch?.name}</div>
                <button
                    type="button"
                    className="h-6 w-6 cursor-pointer border-none"
                    onClick={e => onCloseModal()}>
                    <XIcon />
                </button>
            </div>
            <div className="max-h-fit overflow-y-auto w-full h-full">
                <div className="pt-4 mx-auto max-w-4xl">
                    {stepLogs.map((stepLog, i) => {
                        if (stepLog.appEvent.type === workflowAppType.loop && stepLog.branchId) {
                            return (
                                <LoopIterationLog
                                    key={`${stepLog.id}-${i}`}
                                    stepLog={stepLog}
                                    stepCount={stepLogs.length}
                                    stepIdx={i}
                                />
                            )
                        }

                        return (
                            <StepLogView
                                key={stepLog.id}
                                stepLog={stepLog}
                                stepCount={stepLogs.length}
                                stepIdx={i}
                            />
                        )
                    })}
                </div>
            </div>

        </div>
    )
}