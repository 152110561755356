import CollabsableSection from "./Components/collabsableSection";
import { useClassNames } from "@metaforcelabs/metaforce-core";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import StepLogDataOut from "./stepLogDataOut";
import StepLogSetup from "./stepLogSetup";
import StepLogDataIn from "./stepLogDataIn";

export default function StepLogRunDetails({ stepLog, stepSectionIdx, activeStepSection, setActiveStepSection }) {
    return (
        <CollabsableSection sectionTitle={"Run details"} stepLog={stepLog} sectionIdx={stepSectionIdx} activeSection={activeStepSection} setActiveSection={setActiveStepSection}>
            <div className="mt-3">
                <DataTabs stepLog={stepLog} />
            </div>
        </CollabsableSection>
    )
}

const DataTabs = ({ stepLog }) => {
    const { classNames } = useClassNames();
    const tabs = [
        { name: "Setup", component: <StepLogSetup stepLog={stepLog} /> },
        { name: "Data in", component: <StepLogDataIn stepLog={stepLog} /> },
        { name: "Data out", component: <StepLogDataOut stepLog={stepLog} /> },
    ];
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    return (
        <div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs?.map((tab, index) => (
                            <NavLink
                                key={index}
                                className={classNames(
                                    tab.name === currentTab?.name
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                                    'flex whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium'
                                )}
                                onClick={() => setCurrentTab(tab)}
                            >
                                {tab.name}
                            </NavLink>
                        ))}
                    </nav>
                </div>
            </div>
            {currentTab?.component}
        </div>
    )
}