import React, { useContext, useEffect, useState } from 'react'
import { WorkflowContext, WorkflowStepContext, WorkflowValuesContext } from '../../../../contexts';
import * as SetupActionsSteps from './SetupActionSteps';

export default function SetupActionStep({ workflowStep, setupActionStep, stepIdx }) {

    const valuesContext = useContext(WorkflowValuesContext);
    const { setStepTestResult } = useContext(WorkflowContext);
    const [apiParameters, setApiParameters] = useState([]);

    const createParameters = () => {
        if (!setupActionStep.dependsOn || setupActionStep.dependsOn?.lengths === 0) {
            return [];
        }

        const ret = setupActionStep.dependsOn.map(p => {
            const stepValues = valuesContext.workFlowValues[workflowStep.id];
            if (stepValues && stepValues[p]) {
                return stepValues[p];
            }
            return "";
        });
        if (!ret.every(p => !!p)) {
            return [];
        }

        return ret;
    }

    const handleStepValueChange = (property, value) => {        
        const hasChanged = valuesContext.updateWorkflowStepValue(workflowStep.id, property, value, setupActionStep.triggerStepChangedEvent)
        if (workflowStep.selectedEvent?.useTest && hasChanged) {
            setStepTestResult(workflowStep.id, null);
        }
    }

    useEffect(() => {
        const parameters = createParameters();
        if (!apiParameters.equals(parameters)) {
            setApiParameters(parameters);
        }

    }, [valuesContext.workFlowValues])

    if (setupActionStep.displayCondition) {
        const propValue = valuesContext.getWorkflowStepValue(workflowStep.id, setupActionStep.displayCondition.property)?.value
        if (!propValue || propValue !== setupActionStep.displayCondition.value) {
            return <></>;
        }
    }

    switch (setupActionStep.type) {
        case 'section-start': return (
            <>
                <div className='mt-6 font-semibold'>{setupActionStep.name}:</div>
            </>
        )
        case 'checkbox-field': return (
            <SetupActionsSteps.CheckboxField
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                apiParameters={apiParameters}
                workflowStep={workflowStep}
                onStepValueChange={(property, value) => {
                    handleStepValueChange(property, value);
                }} />
        )
        case 'input-field': return (
            <SetupActionsSteps.InputField
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                apiParameters={apiParameters}
                workflowStep={workflowStep}
                onStepValueChange={(property, value) => {
                    handleStepValueChange(property, value);
                }} />
        )
        case 'input-secret': return (
            <SetupActionsSteps.InputSecret
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                apiParameters={apiParameters}
                workflowStep={workflowStep}
                onStepValueChange={(property, value) => {
                    handleStepValueChange(property, value);
                }} />
        )
        case 'input-dictionary': return (
            <SetupActionsSteps.InputDictionary
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                apiParameters={apiParameters}
                workflowStep={workflowStep}
                onStepValueChange={(property, value) => {
                    handleStepValueChange(property, value);
                }} />
        )
        case 'input-list': return (
            <SetupActionsSteps.InputList
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                apiParameters={apiParameters}
                workflowStep={workflowStep}
                onStepValueChange={(property, value) => {
                    handleStepValueChange(property, value);
                }} />
        )
        case 'nodes': return (
            <SetupActionsSteps.NodesSetupActionStep
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                apiParameters={apiParameters}
                stepIdx={stepIdx}
                workflowStep={workflowStep}
                onStepValueChange={(property, value) => {
                    handleStepValueChange(property, value);
                }} />
        )
        case 'api-options': return (
            <SetupActionsSteps.ApiOptionsSetupActionStep
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                onStepValueChange={(property, value) => {
                    handleStepValueChange(property, value);
                }} />
        )
        case 'options': return (
            <SetupActionsSteps.OptionsSetupActionStep
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                onStepValueChange={(property, value) => {
                    handleStepValueChange(property, value);
                }}
            />
        )
        case 'filter-conditions': return (
            <SetupActionsSteps.FilterConditionsSetupActionStep
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                workflowStep={workflowStep}
            />
        )
        case 'branch-setup': return (
            <SetupActionsSteps.BranchSetupActionStep
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                workflowStep={workflowStep}
            />
        )
        case 'distribution-setup': return (
            <SetupActionsSteps.DistributionSetupActionStep
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                workflowStep={workflowStep}
            />
        )
        // case 'webhook-setup': return (
        //     <SetupActionsSteps.WebhookSetupActionStep
        //         workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
        //         setupActionStep={setupActionStep}
        //         workflowStep={workflowStep}
        //     />
        // )
        case 'loop-setup': return (
            <SetupActionsSteps.LoopSetupStep
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                workflowStep={workflowStep}
            />
        )
        case 'viewpoint-metadata-setup': return (
            <SetupActionsSteps.ViewpointSetupActionStep
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                workflowStep={workflowStep}
                onStepValueChange={(property, value) => {
                    handleStepValueChange(property, value);
                }}
            />
        )
        case 'scheduler-setup': return (
            <SetupActionsSteps.ScheduleSetupActionStep
                workflowStepValues={valuesContext.workFlowValues[workflowStep.id] || {}}
                setupActionStep={setupActionStep}
                apiParameters={apiParameters}
                workflowStep={workflowStep}
                onStepValueChange={(property, value) => {
                    handleStepValueChange(property, value);
                }} />
        )
        default: return (<></>);
    }
}


