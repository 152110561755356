import { stringToLocaleDateTimeString } from "@metaforcelabs/metaforce-core";
import { workflowStepType } from "../../../../utils/constants";
import WorkflowEventAppIcon from "../workflowAppIcon";
import StepLogStatus from "./stepLogStatus";

export default function StepLogHeader({ stepLog, stepIdx = 0, name = '' }) {
    return (
        <div className="flex justify-between space-x-4 items-center px-4 py-5 sm:px-6 bg-white rounded-lg">
            <div className="flex items-center space-x-2 sm:space-x-4 w-full">
                <WorkflowEventAppIcon iconKey={stepLog.appEvent.appIcon} className="flex-none rounded-lg border h-14 w-14 p-2 shadow" />
                <div className="w-full">
                    <div className="flex justify-between">
                        <div>
                            <span className="text-gray-600">
                                {
                                    stepLog.stepType === workflowStepType.trigger && ("Trigger")
                                }
                                {
                                    stepLog.stepType === workflowStepType.action && ("Action")
                                }
                            </span>
                            <StepLogStatus status={stepLog.status} />
                        </div>
                        <span className="text-sm text-gray-700 mt-1">Run: {stringToLocaleDateTimeString(stepLog?.createdDate)}</span>
                    </div>
                    {
                        <h3 className="py-1 font-semibold border border-white">
                            {stepIdx + 1}. {name ? name : stepLog.name}
                        </h3>
                    }
                </div>
            </div>
        </div>
    )
}