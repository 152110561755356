import { ChevronDownIcon } from "@heroicons/react/outline";

export default function StepLogNextStepArrow({ isLastStep }) {
    return !isLastStep && (
        <div className="w-full flex flex-col items-center">
            <div className="w-0.5 h-10 bg-gray-500 relative">
            </div>
            <div className="relative text-gray-500 -mt-3 -mb-1">
                <ChevronDownIcon className="h-5 w-5" />
            </div>
        </div>
    );
}