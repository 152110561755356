import StepLogHeader from "./stepLogHeader";
import StepLogMessage from "./stepLogMessage";
import StepLogNextStepArrow from "./stepLogNextStepArrow";

export default function LoopIterationLog({ stepLog, stepCount, stepIdx }) {
    const isLastStep = stepCount - 1 === stepIdx;
    const name = `${stepLog.name} (Iteration: ${stepLog.loopTracker?.currentIteration})`;

    return (
        <div className="max-w-4xl relative group">
            <div className="rounded-lg border shadow-lg divide-y divide-gray-200">
                <StepLogHeader
                    stepLog={stepLog}
                    stepIdx={stepIdx}
                    name={name}
                />
                <StepLogMessage stepLog={stepLog} />
            </div>
            <StepLogNextStepArrow isLastStep={isLastStep} />
        </div>
    )
}