import { workflowLogStatus } from "../../../../utils/constants";

export default function StepLogMessage({ stepLog }) {
    let classNames = "bg-gray-100";
    switch (stepLog.status) {
        case workflowLogStatus.success:
            classNames = "bg-#def3e9";
            break;
        case workflowLogStatus.failed:
            classNames = "bg-#fadfd9";
            break;
        default:
            classNames = "bg-gray-100";
            break;
    }

    return stepLog.errorMessage && (
        <div className={"px-4 sm:px-6 py-3 text-sm font-semibold text-gray-900 " + classNames}>
            {stepLog.errorMessage}
        </div>
    )
}