import { useContext } from "react";
import { useBranchAbbrevation } from "../../../../hooks/useBranchAbbrevation";
import StepLogMessage from "./stepLogMessage";
import StepLogStatus from "./stepLogStatus";
import { WorkflowRunContext } from "../../../../contexts";

export default function BranchStepLog({ branch, isLoop = false }) {
    const { getBranchAbbrevation } = useBranchAbbrevation();
    const { setBranchLog, setBranchLogModalOpen } = useContext(WorkflowRunContext);

    return (
        <div
            key={branch.workflowBranch.id}
            tabIndex={0}
            className="flex flex-col divide-y divide-gray-200 rounded-lg bg-gray-100 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
            onClick={() => {
                setBranchLog(branch)
                setBranchLogModalOpen(true)
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    setBranchLog(branch)
                    setBranchLogModalOpen(true)
                }
            }}
        >
            <div className="flex justify-between items-center py-2 pl-2 pr-4">
                <div className="flex flex-1 space-x-4 items-center">
                    <div className="flex-none bg-gray-200 rounded-lg p-2 font-bold focus:ring-none">{getBranchAbbrevation(branch.workflowBranch.id)}</div>
                    <div className="flex-1 pr-4">
                        <span>{branch.workflowBranch.name}</span>
                        {isLoop ?
                            <>
                            <span className="px-3">Iteration: {branch.currentIteration}</span>
                            </>
                            :
                            <StepLogStatus status={branch.status} />
                        }
                    </div>
                </div>
            </div>
            <StepLogMessage stepLog={branch} />
        </div>
    )
}