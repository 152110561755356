import { useContext, useState } from "react";
import BranchStepLog from "./branchStepLog";
import StepLogRunDetails from "./stepLogRunDetails";
import StepLogHeader from "./stepLogHeader";
import StepLogMessage from "./stepLogMessage";
import StepLogNextStepArrow from "./stepLogNextStepArrow";
import { WorkflowRunContext } from "contexts";

export default function StepLogView({ stepLog, stepCount, stepIdx }) {
    const isLastStep = stepCount - 1 === stepIdx;
    const [activeStepSection, setActiveStepSection] = useState(0);
    const { setActiveStepLog } = useContext(WorkflowRunContext);

    return (
        <div className="max-w-4xl relative group">
            <div className="rounded-lg border shadow-lg divide-y divide-gray-200 bg-white"
                onClick={e => {
                    setActiveStepLog(stepLog);
                }}
            >
                <StepLogHeader
                    stepLog={stepLog}
                    stepIdx={stepIdx}
                />
                <StepLogMessage stepLog={stepLog} />
                <StepBranches stepLog={stepLog} />
                <StepLogRunDetails stepLog={stepLog} stepSectionIdx={0} activeStepSection={activeStepSection} setActiveStepSection={setActiveStepSection} />
            </div>
            <StepLogNextStepArrow isLastStep={isLastStep} />
        </div>
    )
}

const StepBranches = ({ stepLog }) => {
    return stepLog.branches?.length > 0 && (
        <div className='px-6 py-4 space-y-3 bg-white'>
            {
                stepLog.branches.map((branch) => (
                    <BranchStepLog branch={branch} />
                ))
            }
        </div>
    )
}