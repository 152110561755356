import { useToastAction } from "@metaforcelabs/metaforce-core";
import { getDataOut } from "../../../../api/workflowLog";
import { useEffect, useState } from "react";
import RunDataItem from "./Components/runDataItem";
import { SearchIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";

export default function StepLogDataOut({ stepLog }) {
    const [outputDatas, setOutputDatas] = useState([]);
    const [searchItem, setSearchItem] = useState();

    const loadAction = useToastAction();

    useEffect(() => {
        loadData();
    }, []);

    const notFoundHandler = () => {
        toast.error(`Workflow values (id=${stepLog.workflowValuesId}) is not found.`);
    }

    const loadData = async () => {
        loadAction.execute(async () => {
            const result = await getDataOut(stepLog.workflowValuesId, stepLog.workflowStepDefinitionId, notFoundHandler);
            setOutputDatas(result);
        }, "Failed to load")
    }

    return (
        <div className="">
            <div className="w-full my-6">
                <label htmlFor="desktop-search-candidate" className="sr-only">
                    Search
                </label>
                <div className="flex rounded-md shadow-sm">
                    <div className="relative flex-grow focus-within:z-10">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <SearchIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                            type="text"
                            name="desktop-search-data"
                            id="desktop-search-data"
                            className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                            placeholder="Search data..."
                            onChange={e => setSearchItem(e.target.value)}
                            value={searchItem}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col space-y-5 w-full">
                {
                    outputDatas?.filter(x => searchItem ? x.valueId.toLowerCase().includes(searchItem.toLowerCase()) : true).map((runData, index) => (
                        <RunDataItem key={index} runData={runData} />
                    ))
                }
            </div>
        </div>
    )
}