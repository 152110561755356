import { ViewGridIcon } from '@heroicons/react/outline';
import React, { useContext } from 'react'
import { BranchContext, WorkflowContext, WorkflowStepContext } from '../../../../contexts';
import { specialApps, workFlowApps } from '../../../../domain/workflowApps';
import { appEventIconName, workflowStepType } from '../../../../utils/constants';
import WorkflowEventAppIcon, { } from '../workflowAppIcon';
import { useWorkflowStep } from '../../../../hooks/useWorkflowStep';
import { useHasProductLicensed } from 'hooks/useHasProductLicensed';
import { NO_STEP_EVENT_ALLOWED, useWorkflowhelper } from '../../../../hooks/useWorkflowHelper';

export default function AppEventList({ onAppSelect, workflowStepId }) {
    const { workFlow: workflow } = useContext(WorkflowContext);
    const { isLastStep,...stepContext} = useContext(WorkflowStepContext);
    const { hasProductLicensed } = useHasProductLicensed();

    const { isTriggerStepLocation } = useWorkflowStep();
    const { getAllowedFutureSteps } = useWorkflowhelper(workflow);

    const allowedStepEvents = getAllowedFutureSteps(workflowStepId);

    const canCreateFilterStep = () => {
        const step = workflow.workflowSteps.find(x => x.id === workflowStepId);
        if (!step) {
            return false;
        }

        return step.stepType === workflowStepType.action && (allowedStepEvents.length === 0 ? true : allowedStepEvents.includes('filter-setup'));
    }

    const canCreateBranchStep = (branchStepEventKey = 'branch-setup') => {
        const step = workflow.workflowSteps.find(x => x.id === workflowStepId);
        if (!step) {
            return false;
        }

        const branchSteps = workflow.workflowSteps.filter(x => x.branchId === step.branchId);
        const stepIdx = branchSteps.findIndex(x => x.id === step.id);
        return branchSteps.length - 1 === stepIdx

            && step.stepType === workflowStepType.action
            // prevent nested branches
            && !step.branchId
            && (allowedStepEvents.length === 0 ? true : allowedStepEvents.includes(branchStepEventKey))
            ;
    }

    const appIncludesTrigger = (app) => {
        return app.events.map(x => x.stepType).includes(workflowStepType.trigger)
    }

    const appIncludesAction = (app) => {
        return app.events.map(x => x.stepType).includes(workflowStepType.action)
    }

    const canCreateDistributionStep = () => {

        return canCreateBranchStep('distribution-setup');

    }

    const canCreateLoopStep = () => {
        return canCreateBranchStep('loop-array');
    }

    const handleSelectApp = (appEvent) => {
        onAppSelect(appEvent);
    }

    return (
        <div className="min-h flex grid grid-cols-6 space-y-4 sm:space-x-4 sm:divide-x sm:divide-y-0 divide-y divide-solid overflow-y-auto">
            <div className="col-span-6 sm:col-span-4" >
                <div className="flex space-x-4 items-center">
                    <div className="flex-none h-12 w-12 p-1 border rounded-lg border-brand-pink text-brand-pink">
                        <ViewGridIcon />
                    </div>
                    <div>
                        <h3 className="text-gray-700 font-semibold">
                            App Event
                        </h3>
                        <div className="text-gray-400">
                            Start the workflow when something happens in a workflow app
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <div>
                        <h3 className="text-gray-700 font-semibold">
                            {
                                isTriggerStepLocation(workflow, workflowStepId) ? "Choose an app to trigger the workflow" : "Choose an app"
                            }
                        </h3>
                    </div>
                    <div className="mt-4 ml-2 pb-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
                        {
                            workFlowApps.filter(x => {
                                if (x.disabled || !hasProductLicensed(x)) {
                                    return false;
                                }
                                if (isTriggerStepLocation(workflow, workflowStepId)) {
                                    return appIncludesTrigger(x);
                                }

                                if (allowedStepEvents.length > 0 && isLastStep) {
                                    return allowedStepEvents.some(e => x.events.map(x => x.key).includes(e));
                                } else if (allowedStepEvents.length > 0 && !isLastStep) {
                                    return allowedStepEvents.some(e =>
                                        x.events.filter(event => !event.allowedFutureStepEvents?.includes(NO_STEP_EVENT_ALLOWED)).map(x => x.key).includes(e));
                                }

                                return appIncludesAction(x);

                            }).map(ae => {
                                return (
                                    <div key={ae.name} className="p-2 flex space-x-2 items-center rounded-lg hover:bg-gray-50 hover:shadow-md cursor-pointer"
                                        onClick={e => handleSelectApp(ae)}>
                                        <WorkflowEventAppIcon iconKey={ae.appIcon} className="flex-none shadow h-8 w-8 p-1 rounded" />

                                        <div className="text-gray-700 font-semibold">
                                            {ae.name}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='col-span-6 sm:col-span-2 flex flex-col space-y-2 px-2 py-4 sm:py-0'>
                {
                    canCreateFilterStep() && (
                        <div className='flex items-center space-x-2 rounded hover:shadow cursor-pointer p-2 border border-solid border-gray-200' onClick={e => handleSelectApp(specialApps.filterApp)}>
                            <WorkflowEventAppIcon iconKey={appEventIconName.filterIcon} className="flex-none shadow h-8 w-8 p-1 rounded" />
                            <div className='text-gray-600 text-sm'>
                                Only continue if condition is met.
                            </div>
                        </div>
                    )
                }
                {
                    canCreateBranchStep() && (
                        <div className='flex items-center space-x-2 rounded hover:shadow cursor-pointer p-2 border border-solid border-gray-200' onClick={e => handleSelectApp(specialApps.branchStep)}>
                            <WorkflowEventAppIcon iconKey={appEventIconName.branchIcon} className="flex-none shadow h-8 w-8 p-1 rounded" />
                            <div className='text-gray-600 text-sm'>
                                Create new path if condition is met.
                            </div>
                        </div>
                    )
                }
                {
                    canCreateDistributionStep() && (
                        <div className='flex items-center space-x-2 rounded hover:shadow cursor-pointer p-2 border border-solid border-gray-200' onClick={e => handleSelectApp(specialApps.distribution)}>
                            <WorkflowEventAppIcon iconKey={appEventIconName.distributionIcon} className="flex-none shadow h-8 w-8 p-1 rounded" />
                            <div className='text-gray-600 text-sm'>
                                Split into multiple paths for every condition met
                            </div>
                        </div>
                    )
                }
                {
                    canCreateLoopStep() && (
                        <div className='flex items-center space-x-2 rounded hover:shadow cursor-pointer p-2 border border-solid border-gray-200' onClick={e => handleSelectApp(specialApps.loop)}>
                            <WorkflowEventAppIcon iconKey={appEventIconName.loopIcon} className="flex-none shadow h-8 w-8 p-1 rounded" />
                            <div className='text-gray-600 text-sm'>
                                Creat a loop from an array value
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
