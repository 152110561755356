import { stringToLocaleDateTimeString, useToastAction } from "@metaforcelabs/metaforce-core";
import { WorkflowContext, WorkflowRunContext } from "../../../../contexts";
import { useContext, useEffect, useState } from "react"
import { getRunLog } from "../../../../api/workflowLog";
import { workflowLogStatus, workflowLogStatusNames } from "../../../../utils/constants";
import StepLogView from "./stepLogView";
import FullScreenModal from "../../../../components/Modals/fullScreenModal";
import BranchStepLogModal from "./branchStepLogModal";

export default function WorkflowRunView({ workflowId, workflowValuesId }) {
    const { workFlow } = useContext(WorkflowContext);
    const [runLog, setRunLog] = useState();
    const [stepLogs, setStepLogs] = useState([]);
    const [branchLogModalOpen, setBranchLogModalOpen] = useState(false);
    const [branchLog, setBranchLog] = useState(false);
    const [activeStepLog, setActiveStepLog] = useState();
    const loadAction = useToastAction();

    useEffect(() => {
        loadData();
    }, [workflowValuesId]);

    const loadData = async () => {
        loadAction.execute(async () => {
            const result = await getRunLog(workflowId, workflowValuesId);
            setRunLog(result);
            setStepLogs(result?.stepLogs?.filter(ws => !ws.branchId) ?? []);
        }, "Failed to load")
    }

    const handleCloseBranchLog = () => {
        setBranchLogModalOpen(false);
        setBranchLog(null);
    }

    return (
        <WorkflowRunContext.Provider value={{
            runLog: runLog,
            setBranchLog: setBranchLog,
            setBranchLogModalOpen: setBranchLogModalOpen,
            activeStepLog: activeStepLog,
            setActiveStepLog: setActiveStepLog,
        }}>
            <div className="pb-4 mb-4 border-b border-gray-200 max-w-4xl sm:flex sm:justify-between">
                <h3 className="text-lg leading-6 font-medium  max-w-xl sm:w-4/6">
                    <span className="text-gray-900 text-lg">{workFlow?.name}</span>
                    <StatusBadge status={runLog?.status} />
                </h3>
                <span className="text-sm text-gray-700 mt-1.5">Run: {stringToLocaleDateTimeString(runLog?.createdDate)}</span>
            </div>
            {stepLogs.map((stepLog, i) => (
                <StepLogView
                    key={stepLog.id}
                    stepLog={stepLog}
                    stepCount={stepLogs.filter(sl => !sl.branchId).length}
                    stepIdx={i}
                />
            ))}
            <FullScreenModal
                open={branchLogModalOpen} setOpen={setBranchLogModalOpen}
            >
                <BranchStepLogModal branchLog={branchLog} onCloseModal={handleCloseBranchLog} />
            </FullScreenModal>
        </WorkflowRunContext.Provider>
    )
}

const StatusBadge = ({ status }) => {
    let color = "text-gray-400";
    switch (status) {
        case workflowLogStatus.success:
            color = "text-green-600";
            break;
        case workflowLogStatus.failed:
            color = "text-red-600";
            break;
        case workflowLogStatus.inProgress:
            color = "text-indigo-600";
            break;
        default:
            color = "text-gray-400";
            break;
    }

    return (
        <>
            <span className={`${color} uppercase mx-2`}> - </span>
            <span className={`${color} uppercase `}>{workflowLogStatusNames[status]}</span>
        </>
    )
}