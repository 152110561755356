import { workflowRunDataType } from "utils/constants";
import TextItem from "./textItem";
import FileList from "./fileList";
import ArrayItem from "./arrayItem";
import ObjectItem from "./objectItem";

export default function RunDataItem({ runData }) {

    switch (runData.type) {
        case workflowRunDataType.file:
            return (
                <FileList runData={runData} />
            )
        case workflowRunDataType.array:
            return (
                <ArrayItem runData={runData} />
            )
        case workflowRunDataType.object:
            return (
                <ObjectItem runData={runData} />
            )
        default:
            return (
                <TextItem runData={runData} />
            )
    }
}