import { workflowLogStatus, workflowLogStatusNames } from "../../../../utils/constants";

export default function StepLogStatus({ status }) {
    let color = "text-gray-400";
    switch (status) {
        case workflowLogStatus.success:
            color = "text-green-600";
            break;
        case workflowLogStatus.failed:
            color = "text-red-600";
            break;
        case workflowLogStatus.inProgress:
            color = "text-indigo-600";
            break;
        default:
            color = "text-gray-400";
            break;
    }

    return (
        <span className={`${color} font-medium mx-3`}>( {workflowLogStatusNames[status]} )</span>
    )
}