import RunDataItem from "./runDataItem";

export default function ObjectItem({ runData }) {
    return (
        <div>
            <label className="block text-base text-gray-700 font-semibold">{runData.valueId}</label>
            <div className="flex flex-col space-y-2" >
                {
                    runData.objectValue && (
                        <>
                            {
                                Object.keys(runData.objectValue).map((key, index) => (
                                    <div key={index} className="grid grid-cols-12 space-x-2">
                                        <div className='col-span-4 w-full border-none rounded-lg bg-gray-100 px-4 py-2 text-base break-all'>
                                            {key}
                                        </div>
                                        <div className='col-span-8'>
                                            <RunDataItem runData={runData.objectValue[key]}/>
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                    )
                }
            </div>
        </div>
    )
}